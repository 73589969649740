<template>
  <iq-main>
    <!-- Loader -->
    <iq-loader :loading="status === 'loading'" />

    <div class="container">
      <div class="container--wrapper">
        <!-- Error if failed -->
        <div v-if="status === 'failed'">
          <iq-empty-state
            icon="bug_report"
            title="Es ist ein Fehler aufgetreten."
            :error="true"
          >
            <div class="md-body-2">
              Bitte versuchen Sie es später noch einmal!
            </div>
          </iq-empty-state>
        </div>

        <!-- Confirmation form -->
        <invite-form :ltt="ltt" v-if="status === 'ready'" />
      </div>
    </div>
  </iq-main>
</template>

<script>
import InviteForm from "@/components/confirmation/InviteForm";
export default {
  name: "ConfirmInvite",
  components: { InviteForm },
  data() {
    return {
      status: "loading",
      ltt: null,
    };
  },
  created() {
    this.getLTT();
  },
  methods: {
    getLTT() {
      let ltt = this.$route.query.ltt;
      if (!ltt || ltt.length === 0) {
        this.status = "failed";
      } else {
        this.ltt = ltt;
        this.status = "ready";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/constants";

.container {
  height: 100%;
  padding: $padding;

  &--wrapper {
    border-radius: $border-radius;
    border: $border-default;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    &--holder {
      text-align: center;
    }
  }
}
</style>
