<template>
  <div style="width: 500px">
    <div class="md-title" style="text-align: center">
      Bitte erstellen Sie Ihren Nutzer
    </div>

    <!-- First & last name -->
    <div class="md-layout md-gutter">
      <!-- First name -->
      <div class="md-layout-item">
        <md-field>
          <label>Vorname</label>
          <md-input
            type="text"
            autocomplete="given-name"
            v-model="first_name"
            required
          />
        </md-field>
      </div>

      <!-- Last name -->
      <div class="md-layout-item">
        <md-field>
          <label>Nachname</label>
          <md-input
            type="text"
            autocomplete="family-name"
            v-model="last_name"
            required
          />
        </md-field>
      </div>
    </div>

    <!-- Password -->
    <md-field :class="passwordError ? 'md-invalid' : ''">
      <label>Passwort</label>
      <md-input
        type="password"
        autocomplete="off"
        v-model="password"
        required
        @input="checkPasswordLength"
      />
      <span class="md-helper-text"
        >Passwort muss mindestens 8 Zeichen lang sein</span
      >
      <span class="md-error">Passwort muss mindestens 8 Zeichen lang sein</span>
    </md-field>

    <!-- Confirm password -->
    <md-field :class="confirmPasswordError ? 'md-invalid' : ''">
      <label>Passwort bestätigen</label>
      <md-input
        type="password"
        autocomplete="off"
        v-model="confirm_password"
        required
        @input="comparePasswords"
      />
      <span class="md-error">Passwörter stimmen nicht überein</span>
    </md-field>

    <!-- Submit button -->
    <div>
      <md-button
        class="md-primary button-adjuster"
        @click="acceptInviteAndCreateUser"
        >Benutzer erstellen</md-button
      >
    </div>

    <!-- snackbars -->
    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--error"
      :md-active.sync="isError"
    >
      {{ errorMessage }}
    </md-snackbar>

    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--success"
      :md-active.sync="isSuccess"
    >
      {{ successMessage }}
    </md-snackbar>
  </div>
</template>

<script>
import Settings from "@/config";
import axios from "axios";
import errorMixin from "@/mixins/errorMixin";
import successMixin from "@/mixins/successMixin";

const CONFIRM_URI = Settings.API_URI_CONFIRM;

export default {
  name: "InviteForm",
  mixins: [errorMixin, successMixin],
  props: {
    ltt: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      first_name: null,
      last_name: null,
      password: "",
      confirm_password: "",
      passwordError: false,
      confirmPasswordError: false,
    };
  },
  methods: {
    acceptInviteAndCreateUser() {
      let params = new URLSearchParams();
      params.set("ltt", this.ltt);

      let config = {
        method: "post",
        url: `${CONFIRM_URI}/invite`,
        params: params,
        data: {
          first_name: this.first_name,
          last_name: this.last_name,
          password: this.password,
        },
      };

      axios(config)
        .then((response) => {
          this.toggleSuccess(
            `Hallo ${response.data.first_name} ${response.data.last_name}! Sie werden weitergeleitet.`
          );
          setTimeout(() => {
            this.$router.push({ name: "Authorization" });
          }, 1500);
        })
        .catch((error) => {
          console.error(error);
          this.toggleError(
            "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal!"
          );
        });
    },
    checkPasswordLength() {
      this.passwordError = this.password.length < 8;
    },
    comparePasswords() {
      this.confirmPasswordError = this.confirm_password !== this.password;
    },
  },
};
</script>

<style scoped></style>
